import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

Vue.use(Router);

const routerOptions = [
    { path: "/", component: "Landing" },
    { path: "/login", component: "Login" },
    { path: "/signup", component: "SignUp" },
    { path: "/forget", component: "Forget" },
    { path: "/home", component: "Home", meta: { requiresAuth: true } },
    { path: "*", component: "NotFound" },
    { path: "/error", component: "NotFound" },
    { path: '/reset/:token', component: "ResetPassword" },
    { path: '/active/:token', component: "Active" }
];

const routes = routerOptions.map(route => {
    return {
        ...route,
        component: () => import(`../components/${route.component}.vue`)
    };
});


let router = new Router({
    mode: "history",
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return
        }
        next('/login')
    } else {
        next()
    }
});


export default router
