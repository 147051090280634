<template>
  <v-app>
    <navigation :color="color" :flat="flat" />
    <v-main class="pt-0">
      <Snackbar />
      <router-view />
    </v-main>
    <v-scale-transition>
      <v-btn
          fab
          v-show="fab"
          v-scroll="onScroll"
          dark
          fixed
          bottom
          right
          color="secondary"
          @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
    <foote />
  </v-app>
</template>

<script>
import Snackbar from "@/components/Snackbar";
import navigation from "@/components/Navigation";
import foote from "@/components/Footer";

export default {
  name: "App",
  data(){
    return {
      fab: null,
      color: "",
      flat: null,
    }
  },
  components: {
    Snackbar,
    navigation,
    foote,
  },
  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },
  head: {
    title: {
      inner: 'SPEECH TO TEXT',
      separator: '-',
      complement: 'Аудио записи в текст онлайн'
    },
    meta: [
      { name: 'application-name', content: 'Speech To Text' },
      { name: 'description', content: 'Сервис по переводу аудио записей в текст', id: 'desc' }
    ],
    link: [
      {
        rel: "icon",
        href: require("@/assets/images/logo.png"),
        sizes: '32x32',
        type: 'image/png'
      },
    ]
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    isHome() {
      return this.$route.path === '/home'
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    logout: function() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
  created: function () {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }

    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('logout')
        }
        throw err;
      });
    });
  }
};
</script>

<style scoped>
.v-main {
  background-color: white;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
</style>
