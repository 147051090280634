<template>
  <div>
    <v-snackbar v-model="show" :color="color" :timeout="timeout" :top="'top'">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "showMessage") {
        this.text = state.text;
        this.color = state.color;
        this.timeout = 3500;
        this.show = true;
      }
    });
  },
  data() {
    return {
      show: false,
      color: "",
      text: "",
      timeout: -1,
    };
  },
};
</script>
