<template>
  <div>
    <v-navigation-drawer
        v-model="drawer"
        app
        temporary
        dark
        :src='require("@/assets/images/bgDrawer.jpg")'
    >
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img src="@/assets/images/logo.png" alt="Logo" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="title">SPEECH TO TEXT</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item-group>
          <span v-if="isLoggedIn">
            <span v-if="!isHome">
              <v-list-item to="/home">
                <v-list-item-title>
                  Личный кабинет
                </v-list-item-title>
              </v-list-item>
            </span>
            <v-list-item @click.prevent="logout">
                <v-list-item-title>
                  Выход
                </v-list-item-title>
            </v-list-item>
          </span>
          <span v-else>
            <v-list-item
                v-for="item in menuItems"
                :key="item.title"
                :to="item.path"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </span>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
        app
        :color="color"
        :flat="flat"
        dark
        class="px-15"
        :class="{ expand: flat }"
    >
      <v-toolbar-title>
        <router-link to="/">
          <v-img :src='require("@/assets/images/logo.png")' max-width="100px" />
        </router-link>

      </v-toolbar-title>
      <v-spacer />
      <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          class="mr-4"
          v-if="isXs"
      />
      <div v-else>
        <span v-if="isLoggedIn">
            <span v-if="!isHome">
              <v-btn text to="/home" elevation="0">
                <span>Личный кабинет</span>
              </v-btn>
            </span>
          <v-btn rounded outlined class="success" text @click.prevent="logout" >
              <span>Выход</span>
            </v-btn>
          </span>
        <span v-else>
            <span v-if="isSignup">
              <v-btn rounded outlined class="success" text to="/login">
                <span>Вход</span>
              </v-btn>
            </span>
            <span v-if="isLogin">
              <v-btn rounded outlined class="success" text to="/signup">
                <span>Регистрация</span>
              </v-btn>
            </span>
            <span v-if="isForget">
              <v-btn rounded outlined class="success" text to="/login">
                <span>Вход</span>
              </v-btn>
            </span>
            <span v-if="isMain">
              <v-btn text to="/signup" class="mr-4">
                <span>Регистрация</span>
              </v-btn>
              <v-btn rounded outlined text to="/login">
                <span>Вход</span>
              </v-btn>
            </span>
        </span>
      </div>
    </v-app-bar>
  </div>
</template>

<style scoped>
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}
</style>

<script>
export default {
  data: () => ({
    drawer: null,
    isXs: false,
    menuItems: [
      { title: 'Регистрация', path: '/signup', icon: 'account_circle', color: 'secondary' },
      { title: 'Вход', path: '/login', icon: 'input', color: 'success' }
    ],
  }),
  props: {
    color: String,
    flat: Boolean,
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
    logout: function() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    isHome() {
      return this.$route.path === '/home'
    },
    isSignup() {
      return this.$route.path === '/signup'
    },
    isLogin() {
      return this.$route.path === '/login'
    },
    isMain() {
      return this.$route.path === '/'
    },
    isForget() {
      return this.$route.path === '/forget'
    },
  },
  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>
