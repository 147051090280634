import Vue from 'vue'
import vuetify from './plugins/vuetify'
import "vuetify/dist/vuetify.min.css";
import App from './App.vue'
import store from '@/store'
import Axios from 'axios'
import router from './router'
import VueHead from 'vue-head'
import VueAnalytics from 'vue-analytics'
import VueYandexMetrika from 'vue-yandex-metrika'
import { VueReCaptcha } from "vue-recaptcha-v3";


Vue.use(VueHead)

Vue.use(VueYandexMetrika, {
  id:  '71995384',
  router: router,
  env: process.env.NODE_ENV
})

Vue.use(VueAnalytics, {
  id: 'UA-190495404-1'
})

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_SITE_KEY });

Vue.prototype.$http = Axios;
Vue.config.productionTip = false;

const token = localStorage.getItem('token');
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = `JWT ${token}`
}

new Vue({
  el: "#app",
  router,
  vuetify,
  store,
  VueHead,
  components: { App },
  template: "<App/>"
});

