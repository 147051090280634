import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex);

export default new Vuex.Store({
    namespaced: true,
    state: {
        status: '',
        token: localStorage.getItem('token') || '',
        user: {},
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, token) {
            state.status = 'success';
            state.token = token;
        },
        auth_error(state) {
            state.status = 'error';
        },
        logout(state) {
            state.status = '';
            state.token = '';
        },
        showMessage(state, payload) {
            state.text = payload.text;
            state.color = payload.color;
        },
    },
    actions: {
        login({ commit }, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request');
                axios({ url: process.env.VUE_APP_API_URL+'/api/v1/account/login', data: user, method: 'POST' })
                    .then(resp => {
                        const token = resp.data.token;
                        localStorage.setItem('token', token);
                        axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
                        commit('auth_success', token);
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error');
                        localStorage.removeItem('token');
                        reject(err)
                    })
            })
        },
        signup({ commit }, user) {
            return new Promise((resolve, reject) => {
                axios({ url: process.env.VUE_APP_API_URL+'/api/v1/account/signup', data: user, method: 'POST' })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error');
                        localStorage.removeItem('token');
                        reject(err)
                    })
            })
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                commit('logout');
                localStorage.removeItem('token');
                delete axios.defaults.headers.common['Authorization'];
                resolve()
                this.$router.push("/")
            })
        },
        showSnack({ commit }, payload) {
            commit("showMessage", payload);
        },
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
    }
})
